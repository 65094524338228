var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeBasic)+" ")]},proxy:true}])},[_c('b-card-body',[_c('h2',{staticClass:"text-dark"},[_vm._v(" Last Reports ")]),_c('b-card-text',{staticClass:"mb-1"},[_c('span',[_vm._v("Last 2 months")])])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage:_vm.pageLength
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'example1')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.example1))])]):(props.column.field === 'example2')?_c('span',{staticClass:"text-nowrap"},[_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":"Insert Value"}})],1)],1):(props.column.field === 'example4')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.example4)}},[_vm._v(" "+_vm._s(props.row.example4)+" ")])],1):(props.column.field === 'example5')?_c('span',{staticClass:"text-nowrap"},[_c('b-form-group',[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):(props.column.field === 'example6')?_c('span',{staticClass:"text-nowrap"},[_c('b-form-group',[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusSquareIcon"}}),_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"d-flex justify-content-center flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing 1 to 7 ")]),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of 100 entries ")])]),_c('div')])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }