<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <reports-table-basic />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <reports-sales-radar-chart :data="data.salesChart" />
      </b-col>
      <b-col lg="8">
        <reports-line-area-chart :data="data.lineChart" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import ReportsTableBasic from './ReportsTableBasic.vue'
import ReportsSalesRadarChart from './ReportsSalesRadarChart.vue'
import ReportsLineAreaChart from './ReportsLineAreaChart.vue'

export default {
  components: {
    BRow,
    BCol,
    ReportsTableBasic,
    ReportsSalesRadarChart,
    ReportsLineAreaChart
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>